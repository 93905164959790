<template>
  <div class="wrap">
    <div class="content">
      <figure >
        <img src="../assets/brick.png" />
      </figure>
      <ul>
        <li v-for="(link, index) in links">
          <a v-if="link.external" :href="link.url" target="_new" rel="noopener noreferrer">{{ link.title }}</a>
          <a v-else :href="link.url">{{ link.title }}</a><span v-if="index < links.length - 1">,&nbsp;</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      links: [
        {
          url: 'mailto:hello@timduvendack.de',
          title: 'Mail',
          external: false
        },
        {
          url: 'https://are.na/tim-duvendack',
          title: 'Arena',
          external: true
        },
        {
          url: 'https://github.com/kx550',
          title: 'Github',
          external: true
        }
      ]
    }
  }
}
</script>

<style scoped>
  img {
    max-width: 100%;
    padding-bottom: 1rem;
    margin: 0 auto;
  }

  ul {
    display: block;
    text-align: center;
    list-style: none;
  }

  li {
    display: inline-block;
  }

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
</style>
